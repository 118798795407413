import { Inject, Injectable } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { Firestore, arrayUnion, doc, serverTimestamp } from '@angular/fire/firestore';
import { ref, Storage, uploadBytesResumable } from '@angular/fire/storage';
import { BehaviorSubject, firstValueFrom, Subscription, skipWhile, combineLatest } from 'rxjs';
import { ActiveToast, ToastrService } from 'ngx-toastr';
import { DeviceDetectorService, DeviceInfo } from 'ngx-device-detector';
import * as Rollbar from 'rollbar';

import { UserService } from '../user/user.service';
import { EquipmentService } from '../equipment/equipment.service';
import { FilenameService } from '../filename/filename.service';
import { SessionsService } from '../sessions/sessions.service';
import { BendixService } from '../bendix/bendix.service';
import { AnalyticsService } from '../analytics/analytics.service';
import { MuteService } from '../mute/mute.service';
import { IdTokenService } from '../id-token/id-token.service';
import { RecordingService } from '../recording/recording.service';
import { TrialService } from '../trial/trial.service';
import { RollbarService } from '../../services/rollbar/rollbar.service';
import { DolbyService } from '../dolby/dolby.service';
import { IPadService } from '../ipad/ipad.service';
import { VideoRecorderService } from '../video-recorder/video-recorder.service';
import { GeneralToastComponent } from '../../toasts/general-toast/general-toast.component';
import { Plan, RecordingIssue } from '@sc/types';
import { Locations } from '@sc/types';
import { UserModel } from '@sc/types';
import { RecordingInfo, UploadStats } from '@sc/types';
import { WalletService } from '../wallet/wallet.service';
import { AmplionService } from '../amplion/amplion.service';

import * as dayjs from 'dayjs';
import * as dayjsDuration from 'dayjs/plugin/duration';
import { RoleLimits } from '@sc/types';
import { setDoc } from 'firebase/firestore';
import { ScreenRecorderService } from '../screen-recorder/screen-recorder.service';
import { StickAroundToastComponent } from '../../toasts/stick-around-toast/stick-around-toast.component';
import { StatsType } from '@sc/types';
import { StatsService } from '../stats/stats.service';
import { ModalController } from '@ionic/angular';
import { ActionConfirmPage } from '../../modals/action-confirm/action-confirm.page';
import { DailyService } from '../daily/daily.service';

dayjs.extend(dayjsDuration);

@Injectable({
  providedIn: 'root',
})
export class AudioRecorderService {
  studioShow$ = this.sessionsService.studioShow$;
  studioOrg$ = this.sessionsService.studioOrg$;
  studioSession$ = this.sessionsService.studioSession$;
  dolbyConversation$ = this.dolbyService.dolbyConversation$;
  dailyCall$ = this.dailyService.dailyCall$;
  user: UserModel;
  recorder: MediaRecorder;
  microphoneStream: MediaStream;
  timeSlice: number = 8 * 1000;
  filename: string;
  recordingID: string;
  fileCount: number;
  mute = false;
  plan: Plan;
  trialStatus$ = this.trialService.trialStatus$;
  retryCounter = 0;
  chunkCounter = 0;
  deviceInfo: DeviceInfo;
  firstChunkUploaded = false;
  firstChunkTimeout: ReturnType<typeof setTimeout> = null;
  stats: UploadStats;
  watchSubscription: Subscription;
  roleLimits = RoleLimits;
  recordingInfo: Record<string, RecordingInfo> = {};
  recordingUpdate$ = new BehaviorSubject<string>(null);
  warnings$ = new BehaviorSubject<Set<string>>(new Set());
  stickAroundWarningModal: HTMLIonModalElement = null;
  stickAroundWarningModalShown = false;

  constructor(
    @Inject(RollbarService) private rollbar: Rollbar,
    private firestore: Firestore,
    private storage: Storage,
    private amplionService: AmplionService,
    private analyticsService: AnalyticsService,
    private bendixService: BendixService,
    private deviceDetectorService: DeviceDetectorService,
    private dolbyService: DolbyService,
    private dailyService: DailyService,
    private equipmentService: EquipmentService,
    private filenameService: FilenameService,
    private idTokenService: IdTokenService,
    private iPadService: IPadService,
    private muteService: MuteService,
    private recordingService: RecordingService,
    private screenRecorderService: ScreenRecorderService,
    private sessionsService: SessionsService,
    private statsService: StatsService,
    private trialService: TrialService,
    private toastrService: ToastrService,
    private userService: UserService,
    private videoRecorderService: VideoRecorderService,
    private walletService: WalletService,
    private modalController: ModalController
  ) {
    this.deviceInfo = this.deviceDetectorService.getDeviceInfo();
    this.setupUser();
    this.setupPlan();
    this.setupChunkQueue();
    this.setupFilename();
    this.setupMute();
    this.setupRefs();
    if (this.dolbyConversation$.value) this.setupRecordingStartDolby();
    if (this.dailyCall$.value) this.setupRecordingStartDaily();
    this.setupMicrophoneError();
    this.storage.maxUploadRetryTime = 60 * 1000;
  }

  setupRecordingStartDolby() {
    combineLatest([this.recordingService.recording$, this.dolbyConversation$, this.dolbyService.location$]).subscribe(
      ([recording, convo, location]) => {
        if (!convo?.participants?.size) {
          this.stop();
          return;
        }
        const participantIsOnStage =
          location !== Locations.BACKSTAGE &&
          !!Array.from(convo?.participants?.values()).find((p) => p.type === 'user');
        if (convo && recording && participantIsOnStage) this.start();
        else this.stop();
      }
    );
  }

  setupRecordingStartDaily() {
    // no Backstage in Daily calls
    combineLatest([this.recordingService.recording$, this.dailyCall$]).subscribe(([recording, call]) => {
      if (call && recording) this.start();
      else this.stop();
    });
  }

  async start(test?: boolean) {
    if (this.recorder?.state === 'recording') return;
    this.trialService.refreshTrialStatus();
    const timeout = setTimeout(() => {
      this.throwWarning(RecordingIssue.RECORDING_START_DELAYED);
      this.toastrService.warning(
        `Recording start delayed.  Please keep the tab focused to allow recording.`,
        'Recorder Warning',
        {
          progressBar: true,
          progressAnimation: 'decreasing',
          closeButton: true,
          tapToDismiss: false,
          timeOut: 60 * 1000,
          toastComponent: GeneralToastComponent,
        }
      );
      this.analyticsService.track(`${test ? 'test ' : ''}recording start delayed`, {
        sessionID: this.studioSession$.value.sessionID,
        recordingID: this.recordingID,
      });
    }, 5000);
    try {
      this.warnings$.next(new Set([]));
      this.stickAroundWarningModalShown = false;
      if (this.userService.appFocused$.value === false && this.deviceInfo.browser === 'firefox') {
        await this.userService.appFocused$.nextExistingValue((v) => v === true);
      }

      await this.setupRecorder();
      const recordingID = this.recordingID;
      this.recordingService.audioReady$.next(true);
      if (this.recordingInfo[this.recordingID].videoEnabled && this.equipmentService.constraints$.value.video) {
        await this.recordingService.videoReady$.nextExistingValue((v) => v === true);
      }
      if (this.recordingInfo[recordingID].stopped) return;
      this.recorder.start(this.timeSlice);
      clearTimeout(timeout);
      const warnToast: ActiveToast<GeneralToastComponent> = this.toastrService.toasts.find(
        (t) => t.title === 'Recorder Warning'
      );
      if (warnToast) this.toastrService.remove(warnToast.toastId);
    } catch (error) {
      this.recordingService.audioReady$.next(false);
      return;
    }
    this.recordingService.audioReady$.next(false);

    this.analyticsService.track(`started ${test ? 'test ' : ''}recording`, {
      sessionID: this.studioSession$.value.sessionID,
      fileName: this.filename,
      recordingID: this.recordingID,
    });
    this.chunkCounter = 0;
  }

  stop() {
    if (this.recordingInfo[this.recordingID]) this.recordingInfo[this.recordingID].stopped = true;
    if (!this.recorder || this.recorder.state === 'inactive') return;
    this.retryCounter = 0;
    this.recorder.stop();

    this.analyticsService.track(`stopped recording`, {
      sessionID: this.studioSession$.value?.sessionID
        ? this.studioSession$.value?.sessionID
        : this.sessionsService.lastSession.sessionID,
      fileName: this.filename,
      recordingID: this.recordingID,
      duration: this.recordingService.timer$.value,
    });
    this.warnings$.next(new Set());
  }

  setupMute() {
    this.muteService.mute$.subscribe((mute) => {
      this.mute = mute;

      if (this.microphoneStream) {
        if (mute) {
          this.microphoneStream.getAudioTracks().forEach((track: any) => {
            track.enabled = false;
            track.applyConstraints({ volume: 0 });
          });
        } else {
          this.microphoneStream.getAudioTracks().forEach((track: any) => {
            track.enabled = true;
            track.applyConstraints({ volume: 0.85 });
          });
        }
      }
    });
  }

  setupUser() {
    this.userService.activeUser$.subscribe((user) => {
      this.user = user;
    });
  }

  async setupRecorder() {
    await this.filenameService.setupFileName();
    this.recordingService.resetLocalRecordingID();
    this.recordingInfo[this.recordingID] = {
      sessionID: this.studioSession$.value.sessionID,
      showID: this.studioShow$.value.showID,
      showOwner: this.studioShow$.value.showOwner,
      orgID: this.studioOrg$.value.orgID,
      filename: this.filename,
      videoEnabled: this.plan?.videoRecording && this.studioSession$.value?.videoEnabled,
      take: this.studioSession$.value.take,
      queue: new Map(),
      stats: { audio: { ...this.statsService.defaultRecordingStats } },
      lastBytesTransferred: 0,
    };
    this.statsService.updateRecordingStats(
      this.recordingID,
      StatsType.AUDIO,
      this.recordingInfo[this.recordingID].stats.audio,
      this.recordingInfo[this.recordingID].sessionID
    );

    // this should just be ignored for Daily calls
    // if (this.dolbyService.conferenceParams.dolbyVoice || this.dailyService.dailyCall$?.value)
    //   constraints.echoCancellation = true;
    this.microphoneStream = this.dailyService.microphoneStream;
    this.recorder = this.equipmentService.getAudioRecorder(this.microphoneStream);

    this.recordingInfo[this.recordingID].stats.audio.trackSettings = {
      audio: this.recorder.stream.getAudioTracks()[0].getSettings(),
    };

    this.recorder.ondataavailable = this.handleRecording.bind(this);
    this.recorder.stream.getAudioTracks()[0].enabled = !this.mute;
    this.recorder.onstop = this.watchProcessing.bind(this);
    this.recorder.onerror = this.handleError.bind(this);
  }

  setupRefs() {
    this.recordingService.localRecordingID$.subscribe(async (localRecordingID: string) => {
      this.recordingID = localRecordingID;

      await this.filenameService.fileName$.toPromise();

      this.recordingService.setRecording(this.recordingID, {
        fileName: this.filename,
        datetime: serverTimestamp(),
        iOS: this.deviceInfo.device === 'iPhone' || this.iPadService.check() ? true : false,
        deviceInfo: this.deviceInfo,
      });
    });
  }

  setupFilename() {
    this.filenameService.fileName$.subscribe((filename) => {
      if (filename) this.filename = filename;
    });
  }

  setupPlan() {
    this.walletService.studioPlan$.subscribe((plan: Plan) => {
      this.plan = plan;
    });
  }

  setupChunkQueue() {
    this.videoRecorderService.recordingUpdate$.subscribe(async (recordingID) => {
      const info = this.recordingInfo[recordingID];
      const videoInfo = this.videoRecorderService.recordingInfo[recordingID];
      const screenID = this.screenRecorderService.recordingID;
      if (!recordingID) return;
      if (info.awaitingProcessing) {
        info.stickAroundToast = this.toastrService.error(
          `This tab must remain open for your recorded files to be uploaded.`,
          `Do not close this browser tab!`,
          {
            progressBar: false,
            closeButton: false,
            tapToDismiss: false,
            disableTimeOut: true,
            toastComponent: StickAroundToastComponent,
            payload: { recordingID, screenID },
          }
        ) as ActiveToast<StickAroundToastComponent>;
        this.statsService.updateRecordingStats(
          recordingID,
          StatsType.AUDIO,
          info.stats.audio,
          this.recordingInfo[recordingID].sessionID
        );
        if (videoInfo?.queue && !videoInfo.queue.size && info.queue.size === 0) {
          this.processFile(recordingID);
          if (info.stickAroundToast) {
            info.stickAroundToast.toastRef.close();
          }
          if (this.screenRecorderService.recordingInfo[screenID]?.queue.size > 1) {
            this.screenRecorderService.showStickAround(screenID);
          }
        }
      }
      this.showStickAroundWarningModalIfNeeded(recordingID, screenID);
    });
  }

  async showStickAroundWarningModalIfNeeded(recordingID, screenID) {
    if (!this.stickAroundWarningModal) {
      const modal = await this.modalController.create({
        component: ActionConfirmPage,
        componentProps: {
          title: 'Do not close this browser tab!',
          message: `This tab must remain open for your recorded files to be uploaded. You can view your upload progress in the bottom right corner of the screen.`,
          iconSrc: 'assets/icons/16px/exclamation-solid.svg',
          buttons: [
            {
              label: 'Got it!',
              handler: async () => {
                await modal.dismiss();
                this.stickAroundWarningModal = null;
              },
            },
          ],
        },
        showBackdrop: true,
        backdropDismiss: false,
        animated: true,
        cssClass: 'action-confirm-modal',
      });
      this.stickAroundWarningModal = modal;
    }

    const info = this.recordingInfo[recordingID];
    const videoInfo = this.videoRecorderService.recordingInfo[recordingID];
    const screenRecordingInfo = this.screenRecorderService.recordingInfo[screenID];

    if (info?.awaitingProcessing && (videoInfo?.queue?.size || screenRecordingInfo?.queue?.size)) {
      if (!this.stickAroundWarningModalShown) {
        this.stickAroundWarningModalShown = true;
        // allow for normal people to finish the final upload before showing fairly aggressive warning
        await new Promise((r) => setTimeout(r, 2000));
        await this.stickAroundWarningModal?.present();
      }
    } else if (this.stickAroundWarningModalShown) {
      this.stickAroundWarningModal?.dismiss();
      this.stickAroundWarningModal = null;
    }
  }

  updateLedger() {
    if (dayjs.duration(this.recordingService.timer$.value, 'seconds').format('HH:mm:ss') !== '00:00:00') {
      const sessionMembers: string[] = [];

      if (this.dolbyConversation$.value) {
        this.dolbyService.nonPrioritySpeakersArray$.value.forEach((participant) => {
          if (participant.role > this.roleLimits.RECORDING_READ) {
            sessionMembers.push(participant.uid);
          }
        });
      }

      if (this.dailyCall$.value) {
        this.dailyService.nonPrioritySpeakersArray$.value.forEach((participant) => {
          if (participant.role > this.roleLimits.RECORDING_READ) {
            sessionMembers.push(participant.uid);
          }
        });
      }

      const ledgerRef = doc(
        this.firestore,
        'organizations',
        this.studioOrg$.value.orgID,
        'billing',
        'wallet',
        'ledger',
        `${this.studioSession$.value.sessionID}_${this.studioSession$.value.take}`
      );
      const unlimited = this.plan.recordingHours === 'Unlimited';
      setDoc(ledgerRef, {
        type: 'withdraw',
        time: dayjs.duration(this.recordingService.timer$.value, 'seconds').format('HH:mm:ss'),
        date: new Date().getTime(),
        amount: null,
        memberIDs: sessionMembers,
        trial: this.trialStatus$.value?.onTrial !== undefined ? this.trialStatus$.value?.onTrial : false,
        unlimited,
      });
    }
  }

  /**
   *  This will store Wav Chunks in Google Cloud Storage rather than Blob Firestore members colleciton.
   *
   * @param recording
   * @returns
   */
  async handleRecording(recording: { data: Blob }, retryChunk = 0) {
    if (typeof recording.data === 'undefined' || recording.data.size === 0) return;
    if (!retryChunk) this.chunkCounter = this.chunkCounter + 1;
    const chunkNum = retryChunk || this.chunkCounter;
    const recordingID = this.recordingID;
    const recRef = this.recordingInfo[recordingID];
    const filename = recRef.filename;
    const blob = recording.data;

    if (!retryChunk) {
      if (chunkNum === 1) this.setFirstChunkTimeout();
      this.statsService.statsNewChunk(recRef, recordingID, StatsType.AUDIO, chunkNum, blob);
      this.updateLedger();
      await this.readyToUpload(recordingID, chunkNum);
    }

    const chunkRef = ref(this.storage, `${recRef.showID}/${recRef.sessionID}/${filename}/audio`);
    const fileRef = ref(chunkRef, `${chunkNum}.${this.recorder.mimeType.includes('audio/ogg') ? 'webm' : 'mp4'}`);
    const t0 = performance.now();

    const chunkUpload = uploadBytesResumable(fileRef, blob);

    if (!retryChunk) this.statsService.statsStartUpload(recRef, StatsType.AUDIO, chunkNum, blob);

    chunkUpload.on(
      'state_changed',
      (snapshot) => {
        this.statsService.statsChunkProgress(
          recRef,
          recordingID,
          StatsType.AUDIO,
          chunkNum,
          snapshot,
          this.recordingUpdate$
        );
      },
      (error) => {
        this.statsService.statsChunkError(recRef, recordingID, StatsType.AUDIO, chunkNum, this.recordingUpdate$);
        this.rollbar.warn('Audio Chunk Upload Error', { error, recordingInfo: this.recordingInfo });
        this.analyticsService.track('errored uploading Wav audio', {
          showID: recRef.showID,
          sessionID: recRef.sessionID,
          castMemberID: this.user.uid,
          recordingID,
          fileName: filename,
          error: error.message,
        });

        this.toastrService.warning(
          'There is a problem uploading the audio files.  We will continue to retry the upload.  Please double check your network connection.',
          'Audio Upload Stalled',
          {
            closeButton: true,
            tapToDismiss: false,
            timeOut: 20 * 1000,
            toastComponent: GeneralToastComponent,
          }
        );

        this.handleRecording({ data: blob }, chunkNum);
      },
      async () => {
        this.statsService.statsChunkComplete(recRef, recordingID, StatsType.AUDIO, chunkNum, t0, this.recordingUpdate$);
        if (chunkNum === 1) {
          this.firstChunkUploaded = true;
          if (!recRef.awaitingProcessing) {
            this.processPreview();
          }
        }
      }
    );
  }

  async readyToUpload(recordingID: string, num: number) {
    await firstValueFrom(
      this.recordingUpdate$.pipe(
        skipWhile(() => {
          return this.recordingInfo[recordingID].queue.has(num - 1);
        })
      )
    );
    return true;
  }

  watchProcessing() {
    this.recordingInfo[this.recordingID].awaitingProcessing = true;

    // Shows stick around toast right away if needed
    this.videoRecorderService.recordingUpdate$.next(this.recordingID);
    if (this.videoRecorderService.recordingInfo[this.recordingID]) {
      if (this.recordingInfo[this.recordingID].queue.size) {
        const targetID = this.recordingID;
        const watchSubscription = this.recordingUpdate$.subscribe((recordingID) => {
          if (recordingID === targetID) {
            if (
              this.recordingInfo[recordingID].queue.size === 0 &&
              !this.videoRecorderService.recordingInfo[this.recordingID].queue?.size
            ) {
              this.processFile(recordingID);
              watchSubscription.unsubscribe();
            }
          }
        });
      } else this.processFile();
    } else this.processFile();
  }

  processPreview() {
    this.idTokenService.getFreshIdToken().then((idToken: string) => {
      const hasVideoPermission = !!this.equipmentService.constraints$.value.video;
      const usingNewBucket = this.videoRecorderService.recordingInfo[this.recordingID]?.newBucket;

      this.recordingService.updateRecording(this.recordingID, { preview: true });
      this.bendixService
        .processRecording(
          {
            idToken,
            showID: this.studioSession$.value.showID,
            sessionID: this.studioSession$.value.sessionID,
            castMemberID: this.user.uid,
            recordingID: this.recordingID,
            fileName: this.filename,
            renderVideo: this.recordingInfo[this.recordingID].videoEnabled && hasVideoPermission && !usingNewBucket,
            iOS: true, //this.deviceInfo.device === 'iPhone' || this.iPadService.check() ? true : false,
            showOwner: this.studioShow$.value.showOwner,
            preview: true,
          },
          idToken
        )
        .subscribe();
    });
  }

  processFile(recordingID = this.recordingID) {
    this.recordingInfo[recordingID].awaitingProcessing = false;
    this.recordingInfo[recordingID].stickAroundToast?.toastRef.close();
    this.idTokenService.getFreshIdToken().then((idToken: string) => {
      const hasVideoPermission = !!this.equipmentService.hasPermission$.value[1];

      firstValueFrom(
        this.bendixService.processRecording(
          {
            idToken,
            showID: this.recordingInfo[recordingID].showID,
            sessionID: this.recordingInfo[recordingID].sessionID,
            castMemberID: this.user.uid,
            recordingID,
            fileName: this.recordingInfo[recordingID].filename,
            renderVideo: this.recordingInfo[recordingID].videoEnabled && hasVideoPermission,
            iOS: true, // this.deviceInfo.device === 'iPhone' || this.iPadService.check() ? true : false,
            showOwner: this.recordingInfo[recordingID].showOwner,
          },
          idToken
        )
      )
        .then((response) => {
          this.recordingService.updateRecording(this.recordingID, { preview: false });
          return this.amplionService.balanceLedger(this.recordingInfo[this.recordingID].orgID);
        })
        .then(() => {
          this.toastrService.success(
            `Your files have successfully been uploaded.
            <br>
            You may now close this tab.`,
            `File upload complete.`,
            {
              progressBar: true,
              progressAnimation: 'decreasing',
              closeButton: true,
              tapToDismiss: false,
              enableHtml: true,
              timeOut: 10 * 1000,
              toastComponent: GeneralToastComponent,
            }
          );

          this.analyticsService.track('processed recording', {
            recordingID,
            fileName: this.recordingInfo[recordingID].filename,
            renderVideo: hasVideoPermission && (this.plan?.videoRecording || true),
            success: true,
          });
        })
        .catch((error: HttpErrorResponse) => {
          // Retry 5 times in case Firestore doesn't have chunks yet
          if (this.retryCounter < 5 && error?.error?.error?.includes('no chunks')) {
            setTimeout(() => {
              this.processFile();
            }, 1000);

            this.retryCounter++;
            return;
          }

          const { stickAroundToast, ...rollbarInfo } = this.recordingInfo[recordingID];
          this.rollbar.error('Rendering response error: ', error, rollbarInfo);

          const toast: ActiveToast<GeneralToastComponent> = this.toastrService.warning(
            `Cloud Recordings are always available as a backup`,
            `Failed to render HD audio ${this.plan?.videoRecording ?? true ? '& video' : ''} Recordings`,
            {
              progressBar: true,
              progressAnimation: 'decreasing',
              closeButton: true,
              tapToDismiss: false,
              timeOut: 10 * 1000,
              toastComponent: GeneralToastComponent,
            }
          );
          toast.toastRef.componentInstance.learnMoreTopic = 'cloudRecordings';

          this.analyticsService.track('processed recording', {
            recordingID,
            fileName: this.filename,
            renderVideo: hasVideoPermission && (this.plan?.videoRecording || true),
            success: false,
          });
        });
    });
  }

  setFirstChunkTimeout() {
    this.firstChunkTimeout = setTimeout(() => {
      if (!this.firstChunkUploaded) {
        this.throwWarning(RecordingIssue.FIRST_CHUNK_UPLOAD_DELAYED);
        this.rollbar.error('First Audio Chunk Timeout', this.recordingInfo);
        const warnToast: ActiveToast<GeneralToastComponent> = this.toastrService.warning(
          `The audio upload is having a problem.  You may need to reload the page.`,
          'Audio Upload Problem',
          {
            closeButton: true,
            tapToDismiss: false,
            timeOut: 0,
            toastComponent: GeneralToastComponent,
          }
        );
        warnToast.toastRef.componentInstance.buttons = [
          {
            label: 'Reload',
            handler: () => {
              window.location.reload();
            },
          },
        ];
        this.analyticsService.track('timed out uploading first audio chunk', {
          showID: this.studioSession$.value.showID,
          sessionID: this.studioSession$.value.sessionID,
          castMemberID: this.user.uid,
          recordingID: this.recordingID,
          fileName: this.filename,
        });
      }
    }, 60000);
  }

  setupMicrophoneError() {
    this.equipmentService.microphoneError$.subscribe(() => {
      if (this.recordingService.recording$.value) {
        this.throwWarning(RecordingIssue.MICROPHONE_DISCONNECTED);
        this.toastrService.error(
          'Microphone disconnected while recording.  You will need to restart the recording.',
          'Microphone Disconnected',
          {
            progressBar: true,
            closeButton: true,
            toastComponent: GeneralToastComponent,
          }
        );
      }
    });
  }

  throwWarning(msg: RecordingIssue) {
    this.warnings$.next(this.warnings$.value.add(msg));
    this.recordingService.setRecording(this.recordingID, {
      warnings: arrayUnion(msg),
    });
  }

  handleError(error: Error) {
    this.rollbar.error('Audio recorder error: ', error, this.recordingInfo);
    this.toastrService.warning(
      `The audio recording has run into a problem.  You may need to reload the page.`,
      'Audio Recorder Problem',
      {
        progressBar: true,
        progressAnimation: 'decreasing',
        closeButton: true,
        tapToDismiss: false,
        timeOut: 10 * 1000,
        toastComponent: GeneralToastComponent,
      }
    );

    this.analyticsService.track('errored while recording audio', {
      showID: this.studioSession$.value.showID,
      sessionID: this.studioSession$.value.sessionID,
      castMemberID: this.user.uid,
      recordingID: this.recordingID,
      fileName: this.filename,
    });
  }
}
